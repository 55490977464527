<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper d-flex align-items-center">
      <dark-Toggler class="dark-toggler" />
      <span class="system-version ml-2" @click="openModal" v-if="versions.code">
        {{ versions.code }}
      </span>
    </div>

    <!-- Modal Notas de Versão -->
    <b-modal id="modalVersao" title="Notas da Versão" centered>
      <div class="table-responsive">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Título</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(issue, index) in versions.version_issues" :key="index">
              <td>
                {{ issue.issue.summary }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="closeModal">Fechar</b-button>
      </template>
    </b-modal>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- Bell Icon Button with Dropdown Template -->
      <b-nav-item-dropdown right class="d-flex bell-nav">
        <template #button-content>
          <b-link class="nav-link">
            <feather-icon icon="BellIcon" size="21" class="bell-icon" />
            <span class="notification-badge" v-if="newNotificationCount > 0">
              {{ newNotificationCount }}
            </span>
          </b-link>
        </template>
        <!-- Exibe a lista de notificações ao clicar no sino -->
        <b-dropdown-item
          v-for="notification in notifications"
          :key="notification.id"
          @click="read(notification)"
        >
          {{ notification.description }}
        </b-dropdown-item>
        <b-dropdown-item @click="readAll" v-if="notifications.length > 0"
          ><b>Ler todas</b></b-dropdown-item
        >
        <b-dropdown-item @click="readAll" v-else
          ><b>Você não possui novas notificações.</b></b-dropdown-item
        >
      </b-nav-item-dropdown>

      <!-- Dropdown do usuário -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ user.name }}</p>
            <span class="user-status">{{ user.company_name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- Links do dropdown do usuário -->
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Meu Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BModal,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Cookies from "js-cookie"; // Importa a biblioteca de cookies

export default {
  data() {
    return {
      versions: {
        version_issues: [],
      },
      user: {},
      notifications: [],
      newNotificationCount: 0,
      versionUpdateInterval: null,
      posts: [],
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BModal,

    // Componentes do Navbar
    DarkToggler,
  },
  computed: {
    hasUnreadNotifications() {
      return this.notifications.some(
        (notification) =>
          !notification.last_seen &&
          notification.user_id !== this.$store.state.user.id
      );
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show("modalVersao"); // Mostra o modal usando o Bootstrap-Vue
    },
    closeModal() {
      this.$bvModal.hide("modalVersao"); // Oculta o modal usando o Bootstrap-Vue
    },
    logout() {
      let api = this.$store.state.api + "logout";

      axios
        .post(api)
        .then((response) => {
          localStorage.removeItem("token");
          this.$router.push({ path: "/login" });
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$message("Erro", "Erro ao realizar logout", "error");
        });
    },
    async getVersions() {
      const api = this.$store.state.api + "version_env/";

      try {
        const response = await axios.get(api);
        const newVersion = response.data.code;
        console.log("Versões obtidas:", response.data);

        const savedVersion = Cookies.get("current_version");
        if (savedVersion && savedVersion !== newVersion) {
          alert(
            "Uma nova versão está disponível. Por favor, atualize o sistema."
          );
        }

        if (savedVersion !== newVersion) {
          this.saveVersionInCookie(newVersion);
        }

        this.versions = response.data;
      } catch (error) {
        this.$message(null, error.response.data, "error");
      }
    },

    saveVersionInCookie(versionCode) {
      Cookies.set("current_version", versionCode, { expires: 7, path: "/" });
    },

    checkVersion() {
      const savedVersion = Cookies.get("current_version");
      if (savedVersion && savedVersion !== this.versions.code) {
        alert(
          "Uma nova versão está disponível. Por favor, atualize o navegador."
        );
      }
    },
    updateVersionCookie() {
      this.getVersions();
    },
    read(notification) {
      const api = this.$store.state.api + "notification/read";

      axios
        .post(api, { id: notification.id })
        .then((response) => {
          notification.is_read = true;

          this.newNotificationCount = this.notifications.filter(
            (n) => !n.is_read
          ).length;
          this.getNotifications();
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },

    readAll() {
      const api = this.$store.state.api + "notification/readAll";

      axios
        .post(api)
        .then((response) => {
          this.notifications.forEach((notification) => {
            notification.is_read = true;
          });

          this.newNotificationCount = 0;
          this.getNotifications();
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },

    getNotifications() {
      const api = this.$store.state.api + "notifications_user";

      axios
        .get(api)
        .then((response) => {
          this.notifications = response.data;
          this.newNotificationCount = this.notifications.length;
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
  },

  mounted() {
    this.user = this.$store.state.user;
    this.getVersions();
    this.getNotifications();

    window.Echo.channel("posts").listen("PostCreated", (e) => {
      const currentUserId = this.$store.state.user.id;
      if (e.post.user_id !== currentUserId) {
        this.notifications.unshift(e.post); // Adiciona o novo post na lista de notificações
        this.newNotificationCount++; // Incrementa o contador de notificações
        this.getNotifications();
      }
    });
    window.Echo.channel("likes").listen("LikeCreated", (e) => {
      const currentUserId = this.$store.state.user.id;
      if (e.like.user_id !== currentUserId) {
        this.notifications.unshift(e.like); // Adiciona o novo post na lista de notificações
        this.newNotificationCount++; // Incrementa o contador de notificações
        this.getNotifications();
      }
    });
    window.Echo.channel("likes_deleted").listen("LikeDeleted", (e) => {
      const currentUserId = this.$store.state.user.id;

      // Verifique se o evento contém as propriedades necessárias
      if (e.userId && e.postId && e.userId !== currentUserId) {
        // Remova a notificação correspondente à exclusão do like
        const notificationIndex = this.notifications.findIndex(
          (notification) =>
            notification.post_id === e.postId &&
            notification.user_id === e.userId
        );

        if (notificationIndex !== -1) {
          this.notifications.splice(notificationIndex, 1); // Remove a notificação
          this.newNotificationCount--; // Decrementa o contador de notificações
        }
      }
    });

    // this.$nextTick(() => {
    //   this.newNotificationCount = this.notifications.filter(
    //     (notification) =>
    //       !notification.last_seen &&
    //       notification.user_id !== this.$store.state.user.id
    //   ).length;
    // });

    this.versionUpdateInterval = setInterval(() => {
      this.updateVersionCookie();
    }, 3600000);
  },
  beforeDestroy() {
    clearInterval(this.versionUpdateInterval);
  },
};
</script>

<style scoped>
.system-version {
  font-weight: bold;
  margin-left: 10px;
}

.dark-toggler {
  cursor: pointer;
  font-size: 18px;
  margin-right: 15px;
  margin-left: 10px;
  padding-bottom: 3px;
  color: #333;
  display: flex;
  align-items: center;
}

.bell-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.bell-icon {
  font-size: 21px;
  color: #333;
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 1px;
  right: 8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2em 0.5em;
  font-size: 0.75em;
  font-weight: bold;
  min-width: 1em;
  text-align: center;
  line-height: 1;
}

.unread-notification::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .dark-toggler {
    font-size: 16px;
    margin-right: 10px;
  }

  .bell-nav {
    margin-right: 10px;
  }

  .bell-icon {
    font-size: 18px;
  }

  .notification-badge {
    top: 2px;
    right: 10px;
    font-size: 0.75em;
    padding: 0.15em 0.4em;
  }

  .system-version {
    font-size: 14px;
    margin-left: 5px;
  }
}

@media (max-width: 390px) {
  .bell-nav {
    margin-right: 8px;
  }

  .dark-toggler {
    font-size: 14px;
    margin-right: 8px;
  }
}
</style>

